import * as React from 'react';
import { SVGProps } from 'react';

const SvgExchangeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#374151"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 18.333a8.333 8.333 0 1 1 0-16.667 8.333 8.333 0 0 1 0 16.667Zm0-1.667a6.667 6.667 0 1 0 0-13.333 6.667 6.667 0 0 0 0 13.333Zm-4.167-5.833h7.5v1.666H10V15l-4.167-4.166ZM10 7.499V5l4.167 4.167h-7.5V7.499H10Z" />
    </svg>
  );
};
export default SvgExchangeIcon;
