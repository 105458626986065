import * as React from 'react';
import { SVGProps } from 'react';

const SvgCryptoCheckIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 18.333a8.333 8.333 0 1 1 0-16.667 8.333 8.333 0 0 1 0 16.667Zm0-1.667a6.667 6.667 0 1 0 0-13.333 6.667 6.667 0 0 0 0 13.333Zm-2.5-10h5l2.083 2.917L10 14.166 5.417 9.583 7.5 6.666Zm.858 1.667-.766 1.075L10 11.816l2.408-2.408-.766-1.075H8.358Z"
        fill="#374151"
      />
    </svg>
  );
};
export default SvgCryptoCheckIcon;
